<template>
  <div class="wrapper">
    <div class="content" :style="'min-height:' + minHeight + 'px;'">
      <section class="container">
        <div style="background-color: #fff; padding: 17px 20px">
          <el-page-header @back="goBack" content="详情页面"> </el-page-header>
        </div>
      </section>
      <section class="container course-info">
        <div class="cover" :style="
            'background-image: url(' +
            info.coverimage +
            ');height:' +
            imgHeight +
            'px;width:100%'
          "></div>
        <div class="info">
          <p class="title">{{ info.name }}</p>
          <p class="intro" v-html="info.content"></p>
          <el-tag class="mgr10 mgt10" size="mini" type="success" v-for="(tag, i) in tags" :key="i + 't1'">{{ tag }}
          </el-tag>
        </div>
      </section>
      <section class="container course-list">
        <div class="course-list-header">目录</div>
        <el-input class="mgt10" placeholder="搜索课程" size="small" v-model="title">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
        <el-row class="mgl-10 mgr-10">
          <el-col class="category-item pdl10 pdr10" :xs="12" :sm="8" :md="8" :lg="8" :xl="8" v-for="item in list"
            :key="item.id">
            <a :href="'/category?id='+item.id">
              <img :src="item.coverimage" width="100%" height="100%" />
            </a>
          </el-col>
        </el-row>
        <el-empty v-show="emptyShow" description="暂无内容"></el-empty>
      </section>

    </div>

  </div>
</template>

<script>
  import { courseList } from "../../api";
  export default {
    data() {
      return {
        minHeight: window.innerHeight,
        courseId: this.$route.query.id,
        courseDetail: {},
        imgHeight: 0,
        page: 1,
        info: {},
        list: [],
        tags: [],
        title: '',
        emptyShow: false,
      };
    },
    methods: {
      goBack() {
        this.$router.back();
      },
      search() {
        let self = this;
        //self.page = 1;
        console.log(self.title);
        let title = encodeURIComponent(self.title);
        self.$router.push('/category?id=0&title=' + title);
        //this.getInfoList();
      },
      getInfoList() {
        let self = this;
        courseList({ cid: self.courseId, from: 2, title: self.title, page: self.page }).then(
          (res) => {
            console.log(res);
            if (res.code != 0) {
              self.info = res.data.info;
              self.tags = res.data.info?.tags?.split(",") ?? [];
              //self.list = self.list.concat(res.data.list.data);
              if (self.page == 1 && res.data.list.total == 0) {
                self.emptyShow = true;
              }
              self.list = res.data.list.data;

            }
          }
        );
      },
    },
    mounted() {
      let winWidth = window.innerWidth > 1080 ? 1080 : window.innerWidth;
      this.imgHeight = (winWidth / 16) * 8;

      this.getInfoList();
    },
  };
</script>
<style scoped>
  .course-list {
    background-color: #fafafa;
    width: 100%;
    padding: 1px 20px 0px;
    min-height: 410px;
  }

  .course-info .cover {
    height: 380px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }

  .course-info .info {
    background-color: #fff;
    font-size: 14px;
    padding: 20px;
  }

  .course-info .info .title {
    font-size: 18px;
  }

  .course-info .info .intro {
    font-size: 13px;
    color: #999;
    margin-top: 8px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .course-info .num {
    color: #666;
  }

  .course-list {
    background-color: #fff;
    padding: 0 20px 50px;
    margin-top: 8px;
  }

  .course-list-header {
    height: 60px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    font-size: 18px;
    line-height: 60px;
    color: #555;
    margin-top: 8px;
  }

  .category-item {
    font-size: 14px;
    margin-top: 20px;
  }

  .category-item img {
    border-radius: 10px;
  }
</style>